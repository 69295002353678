const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/runtimemonitor/index.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useMemo } from "react";

import { Parse, useParseQueryFirst } from "parse-hooks";
import {

  Loading,
  useSource,
  usePermission,

  useTranslation,
} from "@opendash/core";

import GreisOperatingHoursSelect from "./GreisOperatingHoursSelect";


const OperatingService = "HG_Runtime";

export default function GreisOperating() {
  const [source] = useSource();
  const t = useTranslation();
  console.log(source);
  const serviceQuery = useMemo(
    () =>
      _optionalChain([source, 'optionalAccess', _ => _.tag])
        ? new Parse.Query(OperatingService).equalTo("spsid", source.tag)
        : null,
    [source]
  );

  const parseUser = Parse.User.current();
  const parseUserItem = parseUser;
  const serviceConfig = useParseQueryFirst(serviceQuery);
  const isAdmin = usePermission("hg:runtime-monitor");

  if (serviceConfig.loading) {
    return React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} );
  }

  return (
    React.createElement(GreisOperatingHoursSelect, {
      spsid: source ? source.tag : undefined,
      admin: isAdmin,
      config: serviceConfig,
      user: parseUserItem,
      name: source ? source.name : "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
    )
  );
}
