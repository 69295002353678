const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/service/GreisServiceCurrent.tsx";import React, { } from "react";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout, } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Parse } from "parse-hooks";
import dayjs from "dayjs";
import {
  Table,
  Button,
  Space,
  Typography,
  Tooltip,
  notification,
  Popconfirm,
} from "antd";
const { Title } = Typography;
import { useSource, useTranslation } from "@opendash/core";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // für isSameOrAfter
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // für isSameOrBefore
import advancedFormat from "dayjs/plugin/advancedFormat"; // für formatierungen
import customParseFormat from "dayjs/plugin/customParseFormat";

import CreateServiceModal from "./GreisServiceNewModal";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);



















export default function GreisServiceCurrent() {
  const t = useTranslation();
  const [source] = useSource();
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = React.useState(false);
  const [currentServices, setCurrentServices] = React.useState(
    []
  );

  //Add Entry
  const [operatingHoursMap, setOperatingHoursMap] = React.useState(new Map());
  const [visible, setVisible] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [currentSPSID, setCurrentSPSID] = React.useState(source.tag);

  //Load ServiceData
  React.useEffect(() => {
    try {
      let currentServicesPuffer = [];
      const ServiceEntry = Parse.Object.extend("HG_Service");
      const query = new Parse.Query(ServiceEntry);
      query.include("source");
      query.find().then((results) => {
        results.forEach((result) => {
          if (
            result.get("service_body").length > 0 &&
            (!result.get("last_services") ||
              result.get("last_services").length < 1 ||
              !result.get("last_services")[
                result.get("last_services").length - 1
              ].active)
          ) {
            let lastService = null;
            if (
              result.get("last_services") &&
              result.get("last_services").length > 0
            ) {
              lastService =
                result.get("last_services")[
                  result.get("last_services").length - 1
                ];
            }
            const serviceName = result.get("source").get("name");
            const serviceNumber =
              result.get("source").get("machineNumber") ||
              result.get("source").get("tag");

            let serviceObject = {
              id: result.get("source").get("tag"),
              number: serviceNumber,
              name: serviceName,
              op: parseInt(result.get("op_hours")),
              services: result.get("service_body"),
              last_ohours: result.get("last_ohours"),
              last_hours_fired: result.get("last_hours_fired"),
              last_op_hours_fired: result.get("last_op_hours_fired"),
              last_services: lastService,
              order: 99999999999,
              currentService: undefined,
            };

            if (result.get("service_body").length > 0) {
              result.get("service_body").forEach((e) => {
                if (e.type == "hour" && e.active == "1") {
                  if (lastService != null) {
                    try {
                      serviceObject.order = new Date(
                        getDateForMain(
                          dayjs(lastService.meta.at_value),
                          e.value
                        ).valueOf()
                      ).getTime();
                    } catch (error) {
                      serviceObject.order = new Date(e.start).getTime();
                    }
                  } else {
                    serviceObject.order = new Date(e.start).getTime();
                  }
                } else if (e.type == "op" && e.active == "1") {
                  var delay = 0;
                  if (parseInt(result.get("last_op_hours_fired")) < 1) {
                    //Last Fired ist da, aber wurde noch nie ausgelöst!
                    delay = e.start - parseInt(result.get("op_hours"));
                  } else {
                    //Last Fired ist da und wurde ausgelöst!
                    var last_fired = parseInt(
                      result.get("last_op_hours_fired")
                    );
                    var current_value = parseInt(e.value);
                    var current_ohours = parseInt(result.get("op_hours"));
                    var nextService = last_fired + current_value;
                    delay = nextService - current_ohours;
                  }
                  serviceObject.order = serviceObject.order + delay;
                }
              });
            }
          } else if (
            result.get("last_services") &&
            result.get("last_services").length > 0 &&
            result.get("last_services")[result.get("last_services").length - 1]
              .active
          ) {
            //console.log("Last Service is active");

            let lastService = null;

            if (result.get("last_services").length > 1) {
              lastService =
                result.get("last_services")[
                  result.get("last_services").length - 2
                ];
            }
            let currentService =
              result.get("last_services")[
                result.get("last_services").length - 1
              ];
            const serviceName = result.get("source").get("name");
            const serviceNumber =
              result.get("source").get("machineNumber") ||
              result.get("source").get("tag");

            let serviceObject = {
              id: result.get("source").get("tag"),
              number: serviceNumber,
              name: serviceName,
              op: parseInt(result.get("op_hours")),
              services: result.get("service_body"),
              last_ohours: result.get("last_ohours"),
              last_hours_fired: result.get("last_hours_fired"),
              last_op_hours_fired: result.get("last_op_hours_fired"),
              last_services: lastService,
              order: 99999999999,
              currentService: currentService,
            };

            currentServicesPuffer.push(serviceObject);
          }
        });
        //console.log(currentServicesPuffer);
        setCurrentServices(currentServicesPuffer);
      });
    } catch (error) {
      console.error(error);
    }
  }, [visible, update]);

  const getDateForMain = (start, value) => {
    function addRealMonth(date, months) {
      const d = dayjs(date).startOf("day");
      const fm = d.add(months, "month");
      const fmEnd = fm.endOf("month");
      return d.date() !== fm.date() && fm.isSame(fmEnd, "day")
        ? fm.add(1, "day")
        : fm;
    }

    let date = dayjs(start); // Wandelt den Startwert in ein Day.js-Objekt um
    if (value === 99) {
      date = dayjs(start).add(15, "minutes"); // 900000 ms = 15 min
    } else if (value === 1) {
      date = addRealMonth(date, 1);
    } else if (value === 2) {
      date = addRealMonth(date, 3);
    } else if (value === 3) {
      date = addRealMonth(date, 6);
    } else if (value === 4) {
      date = addRealMonth(date, 12);
    } else {
      date = addRealMonth(date, 24);
    }

    return date;
  };

  const openNotificationWithIcon = (
    type,
    title,
    description
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const getServiceTypes = (data) => {
    const hasOpActive = data.some(
      (item) => item.type === "op" && item.active === 1
    );
    const hasHourActive = data.some(
      (item) => item.type === "hour" && item.active === 1
    );

    if (hasOpActive && hasHourActive) {
      return t("greis:serviceview.component.type_both");
    } else if (hasOpActive) {
      return t("greis:serviceview.component.type_operating_hours");
    } else if (hasHourActive) {
      return t("greis:serviceview.component.type_time");
    }

    return "";
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      key: "id",
      responsive: ["sm"],
    },
    {
      title: t("greis:serviceview.component.table_source"),
      dataIndex: "name",
      key: "anlage",
    },
    {
      title: t("greis:serviceview.component.table_current_op"),
      dataIndex: "op",
      align: "center",
      key: "betriebsstunden",
      render: (text) =>
        isNaN(text)
          ? "-"
          : text == 0
          ? "-"
          : text + " " + t("greis:serviceview.component.table_content_hours"),
      responsive: ["sm"],
    },
    {
      title: t("greis:serviceview.component.table_alarm_time"),
      dataIndex: "ausgelöstAm",
      key: "ausgelöstAm",
      align: "center",
      render: (text, record) => {
        const date = dayjs(record.currentService.meta.when).format(
          "DD.MM.YYYY"
        );
        const serviceType =
          record.currentService.meta.type == "hour"
            ? t("greis:serviceview.component.type_time")
            : t("greis:serviceview.component.type_operating_hours");
        const serviceValue =
          record.currentService.meta.type == "hour"
            ? ""
            : record.last_op_hours_fired;
        //console.log(record);
        return record.currentService.meta.type == "hour"
          ? `${date} (${serviceType})`
          : `${date} (${serviceValue} ${serviceType})`;
      },
      responsive: ["md"],
    },
    {
      title: t("greis:serviceview.component.table_last_maintain"),
      dataIndex: "lastService",
      align: "center",
      key: "lastService",
      render: (text, record) => {
        if (record.last_services == null) {
          return t("greis:serviceview.component.table_content_no_maintain");
        } else {
          const letzteWartung = dayjs(record.last_services.meta.when);
          const quittiertAm = dayjs(record.last_services.ack.when);
          return `${letzteWartung.format("DD.MM.YYYY")}  (${t(
            "greis:serviceview.component.table_content_ack_at"
          )}: ${quittiertAm.format("DD.MM.YYYY")})`;
        }
      },
      responsive: ["md"],
    },
    {
      title: t("greis:serviceview.component.table_ack"),
      key: "action",
      align: "center",
      render: (_, record) => (
        React.createElement(Popconfirm, {
          title: t("greis:serviceview.component.table_ack"),
          description: t("greis:serviceview.component.table_ack_text"),
          onConfirm: () => ackService(record.id),
          okText: "Quittieren",
          cancelText: t("greis:serviceview.component.table_delete_cancel"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 328}}

          , React.createElement(Button, { type: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 335}}
            , t("greis:serviceview.component.table_do_ack")
          )
        )
      ),
    },
    {
      title: t("greis:serviceview.component.table_action"),
      key: "actions",
      align: "center",
      render: (_, record) => (
        React.createElement(Space, { size: "middle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}
          , React.createElement(Tooltip, { title: t("greis:serviceview.component.table_delete_button"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 347}}
            , React.createElement(Popconfirm, {
              title: t("greis:serviceview.component.table_delete_button"),
              description: t("greis:serviceview.component.table_delete_text"),
              onConfirm: () => deleteEntry(record.id),
              okText: t("greis:serviceview.component.table_delete_button"),
              cancelText: t("greis:serviceview.component.table_delete_cancel"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 348}}

              , React.createElement(Button, { icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 355}} ), danger: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 355}} )
            )
          )
        )
      ),
    },
  ];

  const ackService = async (id) => {
    const params = { spsid: id };
    Parse.Cloud.run("ackservice", params).then((result) => {
      openNotificationWithIcon(
        "success",
        t("greis:serviceview.component.table_acked"),
        t("greis:serviceview.component.table_acked_text")
      );
      setUpdate(!update);
    });
  };

  const deleteEntry = async (id) => {
    try {
      const ServiceEntry = Parse.Object.extend("HG_Service");
      const query = new Parse.Query(ServiceEntry);
      const sourceQuery = new Parse.Query("OD3_Source");
      sourceQuery.equalTo("tag", id);
      query.include("source");
      query.matchesQuery("source", sourceQuery);

      const result = await query.first();
      if (result) {
        result.set("service_body", []);
        result.set("last_services", []);
        result.set("last_hours_fired", 0);
        result.set("last_op_hours_fired", 0);
        result.set("last_ohours", 0);
        result.set("last_hours", 0);
        await result.save();
        openNotificationWithIcon(
          "info",
          t("greis:serviceview.component.info_delete_success"),
          t("greis:serviceview.component.info_delete_success_text")
        );
        setUpdate(!update);
      } else {
        openNotificationWithIcon(
          "error",
          t("greis:serviceview.component.info_delete_error"),
          t("greis:serviceview.component.info_delete_error_text")
        );
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        t("greis:serviceview.component.info_delete_error"),
        t("greis:serviceview.component.info_delete_error_text") + " - " + error
      );
    }
    setUpdate(!update);
  };

  const newService = () => {
    setVisible(true);
  };

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 421}}
      , contextHolder
      , React.createElement(AdminToolbar, {
        title: t("greis:serviceview.label"),
        description: t("greis:serviceview.description_current"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 423}}
      )
      , React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 427}}
        , React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 428}}
          , t("greis:serviceview.component.title_current")
        )
        , React.createElement(Table, {
          dataSource: currentServices,
          columns: columns,
          pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}}
        )
      )
      , React.createElement(CreateServiceModal, {
        edit: edit,
        visible: visible,
        onCancel: () => {
          setEdit(false);
          setVisible(false);
        },
        spsid: currentSPSID, __self: this, __source: {fileName: _jsxFileName, lineNumber: 437}}
      )
    )
  );
}
