const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/transponders/GreisTransponderTable.tsx";import React from "react";

import { Table, Input, Space, Checkbox } from "antd";
import { Icon } from "@opendash/icons";

import { useTranslation, compare } from "@opendash/core";

export default function GreisTranspondersTable({
  transponders,
  updateTransponder,
  loading,
  admin,
}) {
  const t = useTranslation();

  const columns = [
    {
      title: t("greis:transponders.admin.cols.id"),
      dataIndex: "id",
      key: "id",
      sorter: compare((row) => row["id"]),
      render: (id, row) => (
        React.createElement('span', {
          style: { textDecoration: row._deleted ? "line-through" : undefined }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}

          , id
        )
      ),
    },
    {
      title: t("greis:transponders.admin.cols.owner"),
      dataIndex: "owner",
      align: "center",
      key: "owner",
      sorter: compare((row) => row["owner"]),
      render: (v, row) => (
        React.createElement(Input, {
          value: row.owner,
          onChange: (e) => updateTransponder(row.id, "owner", e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        )
      ),
    },
    {
      title: t("greis:transponders.admin.cols.misc"),
      dataIndex: "misc",
      align: "center",
      key: "misc",
      sorter: compare((row) => row["misc"]),
      render: (v, row) => (
        React.createElement(Input, {
          value: row.misc,
          onChange: (e) => updateTransponder(row.id, "misc", e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        )
      ),
    },
    {
      title: t("greis:transponders.admin.cols.misc2"),
      dataIndex: "misc2",
      align: "center",
      key: "misc2",
      sorter: compare((row) => row["misc2"]),
      render: (v, row) => (
        React.createElement(Input, {
          value: row.misc2,
          onChange: (e) => updateTransponder(row.id, "misc2", e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        )
      ),
    },
    {
      title: t("greis:transponders.admin.cols.active"),
      dataIndex: "id",
      align: "center",
      key: "active",
      render: (id, row) => (
        React.createElement(Checkbox, {
          checked: row.active || row.active === undefined,
          onClick: (e) =>
            updateTransponder(
              row.id,
              "active",
              !(row.active || row.active === undefined)
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        )
      ),
    },
  ];

  if (admin) {
    columns.push({
      title: " ",
      dataIndex: "id",
      align: "center",
      key: "actions",
      render: (id, row) => (
        React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
          , !row._deleted && (
            React.createElement('a', { onClick: (e) => updateTransponder(row.id, "_deleted", true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
              , React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} )
            )
          )
          , row._deleted && (
            React.createElement('a', {
              onClick: (e) => updateTransponder(row.id, "_deleted", undefined), __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}

              , React.createElement(Icon, { icon: "fa:undo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
            )
          )
        )
      ),
    });
  }

  return (
    React.createElement(Table, {
      rowKey: (row) => row.id,
      dataSource: transponders,
      columns: columns,
      loading: loading,
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "50", "100"],
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
    )
  );
}
