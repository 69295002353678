const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/transponders/index.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useMemo } from "react";

import { Parse, useParseQueryFirst } from "parse-hooks";
import {
  Loading,
  useSource,
  usePermission,
  useTranslation,
} from "@opendash/core";

import GreisTransponderConfig from "./GreisTransponderConfig";


const GreisDevice = "HG_Device";

export default function GreisTransponders() {
  const [source] = useSource();
  const t = useTranslation();

  const cardsQuery = useMemo(
    () =>
      _optionalChain([source, 'optionalAccess', _ => _.tag])
        ? new Parse.Query(GreisDevice).equalTo("spsid", source.tag)
        : null,
    [source]
  );

  const transponderConfig = cardsQuery
    ? useParseQueryFirst(cardsQuery)
    : { loading: false };

  const isAdmin = usePermission("hg:transponders");

  if (transponderConfig.loading) {
    return React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} );
  }

  return (
    React.createElement(GreisTransponderConfig, {
      spsid: source ? source.tag : undefined,
      transponderConfig: transponderConfig,
      admin: isAdmin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
    )
  );
}
