const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/service/GreisServiceView.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useState } from "react";
import { AdminToolbar } from "@opendash/ui";
import { useSource, useTranslation, AdminLayout } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Parse } from "parse-hooks";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Table,
  Button,
  Space,
  Typography,
  Tooltip,
  notification,
  Popconfirm,
} from "antd";
import CreateServiceModal from "./GreisServiceNewModal";

const { Title } = Typography;
dayjs.extend(utc);



















export default function GreisServiceView({ spsid }) {
  const t = useTranslation();
  const [source] = useSource();
  const [api, contextHolder] = notification.useNotification();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentSPSID, setCurrentSPSID] = useState(source.tag);
  const [changes, setChanges] = useState(false);
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const allServicePuffer = [];
        const ServiceEntry = Parse.Object.extend("HG_Service");
        const query = new Parse.Query(ServiceEntry);
        query.include("source");
        const results = await query.find();

        results.forEach(async (result) => {
          if (
            result.get("spsid") !== "keine" &&
            result.get("service_body").length > 0 &&
            (!result.get("last_services") ||
              result.get("last_services").length < 1 ||
              !result.get("last_services")[
                result.get("last_services").length - 1
              ].active)
          ) {
            const serviceName = result.get("source").get("name");
            const serviceNumber =
              result.get("source").get("machineNumber") ||
              result.get("source").get("tag");

            const lastService =
              _optionalChain([result, 'access', _2 => _2.get, 'call', _3 => _3("last_services"), 'optionalAccess', _4 => _4.slice, 'call', _5 => _5(-1), 'access', _6 => _6[0]]) || null;
            const serviceObject = {
              id: result.get("source").get("tag"),
              number: serviceNumber,
              name: serviceName,
              op: parseInt(result.get("op_hours")),
              services: result.get("service_body"),
              last_ohours: result.get("last_ohours"),
              last_hours_fired: result.get("last_hours_fired"),
              last_op_hours_fired: result.get("last_op_hours_fired"),
              last_services: lastService,
              order: Infinity,
              currentService: undefined,
            };

            result.get("service_body").forEach((e) => {
              if (e.type === "hour" && e.active === 1) {
                try {
                  serviceObject.order = dayjs(
                    _optionalChain([lastService, 'optionalAccess', _7 => _7.meta, 'optionalAccess', _8 => _8.at_value]) || e.start
                  )
                    .add(e.value, "month")
                    .valueOf();
                } catch (e2) {
                  serviceObject.order = new Date(e.start).getTime();
                }
                serviceObject.nextServiceHOUR = getNextServiceDateForHOUR(
                  result.get("last_hours_fired") > 1000000000000
                    ? dayjs(result.get("last_hours_fired")).startOf("day")
                    : dayjs(e.start),
                  parseInt(e.value)
                );
              }

              if (e.type === "op" && e.active === 1) {
                const delay = result.get("last_op_hours_fired")
                  ? parseInt(result.get("last_op_hours_fired")) +
                    parseInt(e.value) -
                    parseInt(result.get("op_hours"))
                  : e.start - parseInt(result.get("op_hours"));
                serviceObject.order += delay;
                serviceObject.nextServiceOP = getNextServiceDateForOP(
                  e.start,
                  result.get("op_hours"),
                  parseInt(e.value),
                  result.get("last_op_hours_fired")
                );
              }
            });

            allServicePuffer.push(serviceObject);
          }
        });

        setAllServices(allServicePuffer);
      } catch (error) {
        console.error(error);
      }
    };

    loadServices();
  }, [visible, changes]);

  const openNotificationWithIcon = (
    type,
    title,
    description
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const getNextServiceDateForOP = (
    start,
    currentOP,
    intervall,
    last_op_hours_fired
  ) => {
    if (currentOP < start) {
      return start - currentOP;
    } else {
      return last_op_hours_fired + intervall - currentOP;
    }
  };

  const getNextServiceDateForHOUR = (
    dateStart,
    intervall
  ) => {
    const today = dayjs().startOf("day").hour(3);
    let nextServiceDate;

    if (today.isBefore(dateStart)) {
      const daysUntilNextService = dateStart.diff(today, "day");
      if (daysUntilNextService < 1) {
        return [1, dateStart];
      } else {
        return [daysUntilNextService, dateStart];
      }
    } else {
      switch (intervall) {
        case 1:
          nextServiceDate = dateStart.add(1, "month");
          break;
        case 2:
          nextServiceDate = dateStart.add(3, "month");
          break;
        case 3: // Halbjährlich (alle 6 Monate)
          nextServiceDate = dateStart.add(6, "month");
          break;
        case 4: // Jährlich
          nextServiceDate = dateStart.add(1, "year");
          break;
        case 5: // Alle 2 Jahre
          nextServiceDate = dateStart.add(2, "year");
          break;
        default:
          throw new Error("Unbekanntes Intervall");
      }

      if (!nextServiceDate || !nextServiceDate.isValid()) {
        throw new Error("nextServiceDate ist ungültig");
      }
      const daysUntilNextService = nextServiceDate.diff(today, "day");
      if (daysUntilNextService < 1) {
        return [1, nextServiceDate];
      } else {
        return [daysUntilNextService, nextServiceDate];
      }
    }
  };

  const getServiceTypes = (data) => {
    const hasOpActive = data.some(
      (item) => item.type === "op" && item.active === 1
    );
    const hasHourActive = data.some(
      (item) => item.type === "hour" && item.active === 1
    );

    if (hasOpActive && hasHourActive) {
      return t("greis:serviceview.component.type_both");
    } else if (hasOpActive) {
      return t("greis:serviceview.component.type_operating_hours");
    } else if (hasHourActive) {
      return t("greis:serviceview.component.type_time");
    }

    return "";
  };

  const deleteEntry = async (id) => {
    try {
      const ServiceEntry = Parse.Object.extend("HG_Service");
      const query = new Parse.Query(ServiceEntry);
      const sourceQuery = new Parse.Query("OD3_Source");
      sourceQuery.equalTo("tag", id);
      query.include("source");
      query.matchesQuery("source", sourceQuery);

      const result = await query.first();
      console.log(result);
      if (result) {
        const name = _optionalChain([result, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("source"), 'access', _11 => _11.get, 'call', _12 => _12("name")]);
        result.set("service_body", []);
        result.set("last_services", []);
        result.set("last_hours_fired", 0);
        result.set("last_op_hours_fired", 0);
        result.set("last_ohours", 0);
        result.set("last_hours", 0);
        await result.save();
        openNotificationWithIcon(
          "info",
          t("greis:serviceview.component.info_delete_success"),
          t("greis:serviceview.component.info_delete_success_text")
        );
        setChanges(!changes);
      } else {
        openNotificationWithIcon(
          "error",
          t("greis:serviceview.component.info_delete_error"),
          t("greis:serviceview.component.info_delete_error_text")
        );
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        t("greis:serviceview.component.info_delete_error"),
        t("greis:serviceview.component.info_delete_error_text") + " - " + error
      );
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "number",
      key: "number",
      align: "center",
      responsive: ["lg"],
    },
    {
      title: t("greis:serviceview.component.table_source"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("greis:serviceview.component.table_type"),
      dataIndex: "services",
      key: "services",
      align: "center",
      render: getServiceTypes,
      responsive: ["md"],
    },

    {
      title: t("greis:serviceview.component.table_current_op"),
      dataIndex: "op",
      align: "center",
      key: "betriebsstunden",
      render: (text) =>
        isNaN(text)
          ? "-"
          : text == 0
          ? "-"
          : text + " " + t("greis:serviceview.component.table_content_hours"),
      responsive: ["md"],
    },
    {
      title: t("greis:serviceview.component.table_last_maintain"),
      dataIndex: "letzteWartung",
      align: "center",
      key: "letzteWartung",
      render: (text, record) => {
        if (record.last_services == null) {
          return t("greis:serviceview.component.table_content_no_maintain");
        } else {
          const letzteWartung = dayjs(record.last_services.meta.when);
          const quittiertAm = dayjs(record.last_services.ack.when);
          return `${letzteWartung.format("DD.MM.YYYY")}  (${t(
            "greis:serviceview.component.table_content_ack_at"
          )}: ${quittiertAm.format("DD.MM.YYYY")})`;
        }
      },
      responsive: ["lg"], //WIr hatten 670 Betriebstunden
    },
    {
      title: t("greis:serviceview.component.table_next_maintain"),
      dataIndex: "nextService",
      key: "nextService",
      align: "center",
      render: (_, record) => {
        if (record.nextServiceHOUR) {
          return (
            React.createElement(React.Fragment, null
              , record.nextServiceHOUR &&
                `${record.nextServiceHOUR[0]} ${
                  record.nextServiceHOUR[0] > 1
                    ? t("greis:serviceview.multi")
                    : t("greis:serviceview.single")
                } ${t(
                  "greis:serviceview.component.table_content_at"
                )} ${record.nextServiceHOUR[1].format("DD.MM.YYYY")}`
              , record.nextServiceHOUR[0] && record.nextServiceOP && " | "
              , record.nextServiceOP &&
                record.nextServiceOP >= 0 &&
                `${record.nextServiceOP} ${t(
                  "greis:serviceview.component.type_operating_hours"
                )}`
              , record.nextServiceOP &&
                record.nextServiceOP < 0 &&
                t("greis:serviceview.component.table_content_due")
            )
          );
        } else if (record.nextServiceOP) {
          return (
            React.createElement(React.Fragment, null
              , record.nextServiceOP &&
                record.nextServiceOP >= 0 &&
                `${record.nextServiceOP} ${t(
                  "greis:serviceview.component.type_operating_hours"
                )}`
              , record.nextServiceOP &&
                record.nextServiceOP < 0 &&
                t("greis:serviceview.component.table_content_due")
            )
          );
        }
      },
    },
    {
      title: t("greis:serviceview.component.table_action"),
      key: "actions",
      align: "center",
      render: (_, record) => (
        React.createElement(Space, { size: "middle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 372}}
          , React.createElement(Tooltip, { title: t("greis:serviceview.component.table_edit_button"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 373}}
            , React.createElement(Button, {
              icon: React.createElement(Icon, { icon: "fa:pen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 375}} ),
              onClick: () => {
                setCurrentSPSID(record.id);
                setEdit(true);
                setVisible(true);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 374}}
            )
          )
          , React.createElement(Tooltip, { title: t("greis:serviceview.component.table_delete_button"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 383}}
            , React.createElement(Popconfirm, {
              title: t("greis:serviceview.component.table_delete_button"),
              description: t("greis:serviceview.component.table_delete_text"),
              onConfirm: () => deleteEntry(record.id),
              okText: t("greis:serviceview.component.table_delete_button"),
              cancelText: t("greis:serviceview.component.table_delete_cancel"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 384}}

              , React.createElement(Button, { icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 391}} ), danger: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 391}} )
            )
          )
        )
      ),
    },
  ];

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 400}}
      , contextHolder
      , React.createElement(AdminToolbar, {
        title: t("greis:serviceview.label"),
        description: t("greis:serviceview.description_all"),
        actions: [
          React.createElement(Button, {
            key: "1",
            type: "primary",
            onClick: () => {
              setEdit(true), setVisible(true);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 406}}

            , t("greis:serviceview.component.new_button")
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 402}}
      )
      , React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}
        , React.createElement(Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 418}}, t("greis:serviceview.component.title"))
        , React.createElement(Table, { dataSource: allServices, columns: columns, pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 419}} )
      )
      , React.createElement(CreateServiceModal, {
        edit: edit,
        visible: visible,
        onCancel: () => {
          setEdit(false);
          setVisible(false);
        },
        spsid: currentSPSID, __self: this, __source: {fileName: _jsxFileName, lineNumber: 421}}
      )
    )
  );
}
