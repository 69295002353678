const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/transponders/GreisTransponderExport.tsx";import React, { useState, useMemo } from "react";

import { unparse } from "papaparse";

import { Modal, Button, Radio } from "antd";

import { useTranslation } from "@opendash/core";

export default function GreisTranspondersExport({ open, close, transponders }) {
  const t = useTranslation();
  const [delimiter, setDelimiter] = useState(";");

  const csv = useMemo(() => {
    return unparse(
      transponders.map((row) => ({
        [t("greis:transponders.admin.cols.id")]: row.id,
        [t("greis:transponders.admin.cols.owner")]: row.owner,
        [t("greis:transponders.admin.cols.misc")]: row.misc,
        [t("greis:transponders.admin.cols.misc2")]: row.misc2,
      })),
      {
        delimiter,
        header: true,
      }
    );
  }, [delimiter, transponders]);

  return (
    React.createElement(Modal, {
      title: t("greis:transponders.admin.export"),
      visible: open,
      onOk: (e) => close(),
      onCancel: (e) => close(),
      footer: [
        React.createElement(Button, { key: "back", onClick: (e) => close(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
          , t("greis:transponders.admin.import_action_cancel")
        ),
        React.createElement(Button, {
          key: "export_action_ok",
          type: "primary",
          //icon={<DownloadOutlined />}
          onClick: (e) => {
            downloadCsv("export.csv", csv);
            close();
          },
          children: t("greis:transponders.admin.export_action_ok"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        ),
      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}

      , React.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, t("greis:transponders.admin.csv_delimiter"))
        , React.createElement(Radio.Group, {
          value: delimiter,
          onChange: (e) => setDelimiter(e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}

          , React.createElement(Radio, { value: ",", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
            , t("greis:transponders.admin.csv_delimiter_comma")
          )
          , React.createElement(Radio, { value: ";", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
            , t("greis:transponders.admin.csv_delimiter_semicolon")
          )
        )
      )
    )
  );
}

function downloadCsv(filename, text) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
