const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/service/GreisServiceNewModal.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }import React, { useState } from "react";
import {
  Alert,
  Modal,
  Radio,
  Input,
  Button,
  DatePicker,
  Select,
  Descriptions,
} from "antd";
import { useSource, useTranslation } from "@opendash/core";
import { Parse } from "parse-hooks";
import dayjs, { } from "dayjs";

const { Option } = Select;








const CreateServiceModal = ({
  edit,
  visible,
  onCancel,
  spsid,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [source] = useSource();
  const t = useTranslation();
  const [currentOPHours, setCurrentOPHours] = useState(0);
  const [machineName, setMachineName] = useState("");
  const [ophoursStatus, setOphoursStatus] = useState(2);
  const [hourStatus, setHourStatus] = useState(2);
  const [mail, setMail] = useState("");
  const [validate, setValidate] = useState(true);
  const [validateText, setValidateText] = useState("");
  const [ophoursValueStart, setOphoursValueStart] = useState("");
  const [ophoursValue, setOphoursValue] = useState("");
  const [myStartDate, setMyStartDate] = useState(null);
  const [hoursValue, setHoursValue] = useState("");
  const [itemDate, setItemDate] = useState([
    { id: 1, name: "Monatlich" },
    { id: 2, name: "Vierteljährlich" },
    { id: 3, name: "Halbjährlich" },
    { id: 4, name: "Jährlich" },
    { id: 5, name: "2-Jährig" },
  ]);

  React.useEffect(() => {
    console.log("LANGUGE", window.localStorage.getItem("opendash:language"));
    const ServiceEntry = Parse.Object.extend("HG_Service");
    const query = new Parse.Query(ServiceEntry);

    const sourceQuery = new Parse.Query("OD3_Source");
    sourceQuery.equalTo("tag", spsid);

    query.include("source");
    query.matchesQuery("source", sourceQuery);

    query.first().then((results) => {
      if (results) {
        setCurrentOPHours(results.get("op_hours"));
        setMachineName(
          results.get("source").get("name") +
            " (" +
            (_nullishCoalesce(results.get("source").get("machineNumber"), () => (
              results.get("source").get("tag")))) +
            ")"
        );
      }
    });
  }, [visible, spsid]);

  React.useEffect(() => {
    if (edit) {
      const ServiceEntry = Parse.Object.extend("HG_Service");

      const query = new Parse.Query(ServiceEntry);
      const sourceQuery = new Parse.Query("OD3_Source");
      sourceQuery.equalTo("tag", spsid);
      query.include("source");
      query.matchesQuery("source", sourceQuery);

      query.first().then((results) => {
        if (results) {
          if (
            Array.isArray(results.get("last_services")) &&
            results.get("last_services").length > 0
          ) {
            const lastService =
              results.get("last_services")[
                results.get("last_services").length - 1
              ];
            if (lastService && lastService.active === true) {
              setIsActive(true);
            }
          }

          setCurrentOPHours(results.get("op_hours"));
          setMail(results.get("service_body")[0].mail);
          setOphoursStatus(results.get("service_body")[0].active);
          setOphoursValueStart(results.get("service_body")[0].start);
          setOphoursValue(results.get("service_body")[0].value);
          setHourStatus(results.get("service_body")[1].active);
          setMyStartDate(
            results.get("service_body")[1].start
              ? dayjs(results.get("service_body")[1].start)
              : null
          );
          setHoursValue(results.get("service_body")[1].value);
        }
      });
    }
  }, [edit, spsid]);

  const sendEntry = async () => {
    // Validate
    let error = 1;
    let validatePuffer = true;

    const operatingHoursValue = parseInt(ophoursValue);
    const operatingHoursValueStart = parseInt(ophoursValueStart);

    if (ophoursStatus === 1 && operatingHoursValue && operatingHoursValue > 0) {
      if (operatingHoursValueStart <= currentOPHours) {
        validatePuffer = false;
        setValidate(false);
        setValidateText(
          t("greis:serviceview.new_modal.validate_text_operating_hours_start")
        );
        error = 2;
      }
    } else if (ophoursStatus !== 2) {
      validatePuffer = false;
      setValidate(false);
      setValidateText(
        t("greis:serviceview.new_modal.validate_text_operating_hours_error")
      );
    }

    if (hourStatus === 1 && hoursValue && parseInt(hoursValue) > 0) {
      const pick = dayjs(myStartDate);
      const now = dayjs().endOf("day");

      if (pick.valueOf() <= now.valueOf()) {
        validatePuffer = false;
        setValidate(false);
        setValidateText(
          t("greis:serviceview.new_modal.validate_text_time_start")
        );
        error = 2;
      }
    } else if (hourStatus !== 2) {
      validatePuffer = false;
      setValidate(false);
      setValidateText(
        t("greis:serviceview.new_modal.validate_text_time_error")
      );
    }

    const re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    if (!mail || mail == "" || (mail && !re.test(mail))) {
      validatePuffer = false;
      setValidate(false);
      setValidateText(
        t("greis:serviceview.new_modal.validate_text_mail_error")
      );
    }

    if (validatePuffer) {
      setValidate(true);
      try {
        const serviceArray = []; // Typ für serviceArray
        const serviceOPHObject = {}; // Typ für serviceOPHObject
        const serviceHObject = {}; // Typ für serviceHObject

        serviceOPHObject.type = "op";
        serviceOPHObject.active = ophoursStatus;
        serviceOPHObject.start = operatingHoursValueStart || 0; // Standardwert
        serviceOPHObject.value =
          ophoursStatus === 2 ? "-1" : operatingHoursValue.toString();
        serviceOPHObject.mail = mail;

        serviceHObject.type = "hour";
        serviceHObject.active = hourStatus;
        serviceHObject.start = myStartDate
          ? dayjs(myStartDate).startOf("day").valueOf()
          : 0;

        serviceHObject.value = hourStatus === 2 ? "-1" : hoursValue;
        serviceHObject.mail = mail;

        serviceArray.push(serviceOPHObject);
        serviceArray.push(serviceHObject);

        const ServiceEntry = Parse.Object.extend("HG_Service");
        const query = new Parse.Query(ServiceEntry);

        const sourcePointer = new Parse.Object("OD3_Source");
        sourcePointer.id = source.id;

        query.include("source");
        query.equalTo("source", sourcePointer);
        const results = await query.find();

        if (results.length === 0) {
          const newService = new ServiceEntry();
          newService.set("source", sourcePointer);
          newService.set("service_body", serviceArray);
          newService.set("op_hours", 0);
          let language = window.localStorage.getItem("opendash:language")
            ? JSON.parse(
                window.localStorage.getItem("opendash:language") || '"de"'
              )
            : "de";
          newService.set("language", language + "");
          await newService.save();
        } else {
          if (edit) {
            if (serviceHObject.start > 0) {
              if (dayjs().startOf("day").valueOf() > serviceHObject.start) {
                let lastFiredFake = serviceHObject.start;
                let lastFiredFakePuffer = lastFiredFake;
                results[0].set("last_hours_fired", lastFiredFakePuffer);
              } else {
                results[0].set("last_hours_fired", 0);
              }
            }
          }
          let language = window.localStorage.getItem("opendash:language")
            ? JSON.parse(
                window.localStorage.getItem("opendash:language") || '"de"'
              )
            : "de";
          results[0].set("language", language + "");
          results[0].set("service_body", serviceArray);
          await results[0].save();
        }

        deleteEntry();

        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const deleteEntry = () => {
    setOphoursStatus(2);
    setOphoursValue("");
    setOphoursValueStart("");
    setHourStatus(2);
    setHoursValue("");
    setMail("");
    setMyStartDate(null);
    setValidate(true);
    onCancel();
  };

  return (
    React.createElement(Modal, {
      title: 
        machineName != ""
          ? t("greis:serviceview.new_modal.title_with") + machineName
          : t("greis:serviceview.new_modal.title")
      ,
      open: visible,
      onCancel: () => {
        deleteEntry();
        onCancel();
      },
      footer: null,
      width: 855,
      style: { maxHeight: "90%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 267}}

      , !validate && (
        React.createElement(React.Fragment, null
          , React.createElement(Alert, { message: validateText, type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 284}} )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 285}} )
        )
      )
      , isActive ? (
        React.createElement(React.Fragment, null
          , React.createElement(Alert, {
            message: t("greis:serviceview.new_modal.alarm_active"),
            type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
          )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 294}} )
        )
      ) : (
        React.createElement(React.Fragment, null
          , React.createElement(Descriptions, { bordered: true, column: 1, style: { marginBottom: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 298}}
            , React.createElement(Descriptions.Item, {
              label: t("greis:serviceview.new_modal.desc_op_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 299}}

              , React.createElement(Radio.Group, {
                onChange: (e) => {
                  setValidate(true);
                  setOphoursStatus(e.target.value);
                },
                value: ophoursStatus, __self: this, __source: {fileName: _jsxFileName, lineNumber: 302}}

                , React.createElement(Radio, { value: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 309}}
                  , t("greis:serviceview.new_modal.desc_activate")
                )
                , React.createElement(Radio, { value: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}
                  , t("greis:serviceview.new_modal.desc_deactivate")
                )
              )
            )

            , ophoursStatus === 1 && (
              React.createElement(React.Fragment, null
                , React.createElement(Descriptions.Item, {
                  label: 
                    t("greis:serviceview.new_modal.desc_op_start") +
                    " - " +
                    currentOPHours +
                    "):"
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 320}}

                  , React.createElement(Input, {
                    placeholder: t(
                      "greis:serviceview.new_modal.desc_enter_value"
                    ),
                    type: "number",
                    value: ophoursValueStart,
                    onChange: (e) => {
                      setValidate(true);
                      setOphoursValueStart(e.target.value);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 328}}
                  )
                )
                , React.createElement(Descriptions.Item, {
                  label: t("greis:serviceview.new_modal.desc_op_interval"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}}

                  , React.createElement(Input, {
                    placeholder: t(
                      "greis:serviceview.new_modal.desc_enter_value"
                    ),
                    type: "number",
                    value: ophoursValue,
                    onChange: (e) => {
                      setValidate(true);
                      setOphoursValue(e.target.value);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 343}}
                  )
                )
              )
            )

            , React.createElement(Descriptions.Item, {
              label: t("greis:serviceview.new_modal.desc_time_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}

              , React.createElement(Radio.Group, {
                onChange: (e) => {
                  setValidate(true);
                  setHourStatus(e.target.value);
                },
                value: hourStatus, __self: this, __source: {fileName: _jsxFileName, lineNumber: 361}}

                , React.createElement(Radio, { value: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 368}}
                  , t("greis:serviceview.new_modal.desc_activate")
                )
                , React.createElement(Radio, { value: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 371}}
                  , t("greis:serviceview.new_modal.desc_deactivate")
                )
              )
            )

            , hourStatus === 1 && (
              React.createElement(React.Fragment, null
                , React.createElement(Descriptions.Item, {
                  label: t("greis:serviceview.new_modal.desc_time_start"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 379}}

                  , React.createElement(DatePicker, {
                    format: "DD.MM.YYYY",
                    value: myStartDate ? dayjs(myStartDate) : null,
                    onChange: (date) => {
                      setValidate(true);
                      setMyStartDate(date);
                    },
                    style: { width: "100%", padding: 0, margin: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 382}}
                  )
                )
                , React.createElement(Descriptions.Item, {
                  label: t("greis:serviceview.new_modal.desc_time_interval"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 392}}

                  , React.createElement(Select, {
                    value: hoursValue,
                    onChange: (value) => {
                      setValidate(true);
                      setHoursValue(value);
                    },
                    style: {
                      width: "100%",
                      textAlign: "center",
                      textIndent: "25%",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 395}}

                    , itemDate.map((item) => (
                      React.createElement(Option, { key: item.id, value: item.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 408}}
                        , item.name
                      )
                    ))
                  )
                )
              )
            )

            , React.createElement(Descriptions.Item, {
              label: t("greis:serviceview.new_modal.desc_time_mail_receiver"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}

              , React.createElement(Input, {
                placeholder: t(
                  "greis:serviceview.new_modal.desc_time_mail_receiver_hint"
                ),
                value: mail,
                onChange: (e) => setMail(e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 420}}
              )
            )
          )
          , React.createElement('div', { style: { display: "flex", justifyContent: "space-around" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 429}}
            , React.createElement(Button, { type: "primary", onClick: sendEntry, style: { width: "80%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 430}}
              , React.createElement('i', { className: "fa fa-plus-square" , 'aria-hidden': "true", __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}}), " "
              , t("greis:serviceview.new_modal.button_save")
            ), " "

            , React.createElement(Button, { onClick: deleteEntry, style: { width: "80%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 435}}
              , React.createElement('i', { className: "fa fa-trash" , 'aria-hidden': "true", __self: this, __source: {fileName: _jsxFileName, lineNumber: 436}}), " "
              , t("greis:serviceview.new_modal.button_cancel")
            )
          )
        )
      )
    )
  );
};

export default CreateServiceModal;
