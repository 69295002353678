const _jsxFileName = "/Users/nico/Developer/open.DASH/opendash-instance-mobileenergy/src/js/components/transponders/GreisTransponderConfig.tsx";import React, { useMemo } from "react";
import styled from "styled-components";
import { Icon } from "@opendash/icons";

import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";

import { Alert, Button } from "antd";

import { useTranslation, copy } from "@opendash/core";

import GreisTransponderTable from "./GreisTransponderTable";
import GreisTransponderImport from "./GreisTransponderImport";
import GreisTransponderExport from "./GreisTransponderExport";
import GreisTransponderAdd from "./GreisTransponderAdd";

const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  > button {
    margin-left: 8px;
  }
`;

export default function GreisTransponderConfig({
  spsid,
  transponderConfig,
  admin,
}) {
  const { result, loading, error } = transponderConfig;

  const t = useTranslation();
  const [transponders, setTransponders] = React.useState([]);
  const [importModal, setImportModal] = React.useState(false);
  const [exportModal, setExportModal] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);

  React.useEffect(() => {
    if (result) {
      setTransponders(copy(result.get("cardIDs")));
    } else {
      setTransponders([]);
    }
  }, [result]);

  const changes = useMemo(() => {
    let count = 0;
    let countNew = 0;
    let countDeleted = 0;

    for (const t of transponders) {
      if (t._deleted) {
        count++;
        countDeleted++;
        continue;
      }

      const oldVersion = result.get("cardIDs").find((r) => r.id === t.id);

      if (!oldVersion) {
        count++;
        countNew++;
        continue;
      }

      const diff = JSON.stringify(t) !== JSON.stringify(oldVersion);

      if (diff) {
        count++;
      }
    }

    return { count, countNew, countDeleted };
  }, [transponders]);

  const saveTransponders = () => {
    const toSave = transponders
      .filter((x) => !x._deleted)
      .map((x) => ({
        id: x.id,
        misc: x.misc,
        misc2: x.misc2,
        owner: x.owner,
        active: x.active,
      }));

    result
      .set("cardIDs", toSave)
      .save()
      .then(() => {
        setTransponders(copy(result.get("cardIDs")));
      });
  };

  const addTransponders = (arr) => {
    setTransponders([...transponders, ...arr]);
  };

  const overwriteTransponders = (arr) => {
    setTransponders([...arr]);
  };

  const updateTransponder = (id, key, value) => {
    setTransponders(
      transponders.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [key]: value,
          };
        }

        return row;
      })
    );
  };

  if (!spsid) {
    return (
      React.createElement(Alert, {
        type: "info",
        message: t("greis:transponders.admin.select_location_first"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
      )
    );
  }

  if (!transponderConfig.loading && !transponderConfig.result) {
    return (
      React.createElement(Alert, {
        type: "error",
        message: t("greis:transponders.admin.location_not_supported"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
      )
    );
  }

  const getAction = (isAdmin) => {
    if (isAdmin) {
      return [
        React.createElement(Button, {
          type: "primary",
          disabled: changes.count === 0,
          onClick: (e) => {
            saveTransponders();
          },
          children: t("greis:transponders.admin.save"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
        ),

        React.createElement(Button, {
          icon: React.createElement(Icon, { icon: "fa:download", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}} ),
          disabled: loading,
          onClick: (e) => {
            setImportModal(true);
          },
          children: t("greis:transponders.admin.import_button"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
        ),
        React.createElement(Button, {
          icon: React.createElement(Icon, { icon: "fa:download", __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}} ),
          disabled: loading,
          onClick: (e) => {
            setExportModal(true);
          },
          children: t("greis:transponders.admin.export_button"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
        ),
        React.createElement(Button, {
          icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} ),
          disabled: loading,
          onClick: (e) => {
            setAddModal(true);
          },
          children: t("greis:transponders.admin.add_button"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
        ),
      ];
    } else {
      return [
        React.createElement(Button, {
          type: "primary",
          disabled: changes.count === 0,
          onClick: (e) => {
            saveTransponders();
          },
          children: t("greis:transponders.admin.save"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
        ),
      ];
    }
  };

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
      , React.createElement(AdminToolbar, {
        title: t("greis:transponders.admin.select_location"),
        description: t("greis:transponders.admin.trans_description"),
        actions: getAction(admin), __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
      )
      , React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}
        /* 
    <LinkedSourcePicker
      style={{
        width: "100%",
      }}
    
        <ButtonGroup>
          <Button
            type="primary"
            disabled={changes.count === 0}
            onClick={(e) => {
              saveTransponders();
            }}
            children={t("greis:transponders.admin.save")}
          />
          {admin && (
            <Button
              // type="primary"
              //icon={<UploadOutlined />}
              disabled={loading}
              onClick={(e) => {
                setImportModal(true);
              }}
              children={t("greis:transponders.admin.import_button")}
            />
          )}
          {admin && (
            <Button
              // type="primary"
              //icon={<DownloadOutlined />}
              disabled={loading}
              onClick={(e) => {
                setExportModal(true);
              }}
              children={t("greis:transponders.admin.export_button")}
            />
          )}
          {admin && (
            <Button
              // type="primary"
              //icon={<PlusSquareOutlined />}
              disabled={loading}
              onClick={(e) => {
                setAddModal(true);
              }}
              children={t("greis:transponders.admin.add_button")}
            />
          )}
        </ButtonGroup>/>*/
        , changes.count > 0 && (
          React.createElement(Alert, {
            type: "info",
            message: t("greis:transponders.admin.changes", changes),
            style: { marginBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}
          )
        )

        , React.createElement(GreisTransponderImport, {
          open: importModal,
          close: () => {
            setImportModal(false);
          },
          addTransponders: addTransponders,
          overwriteTransponders: overwriteTransponders, __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}
        )

        , React.createElement(GreisTransponderExport, {
          open: exportModal,
          close: () => {
            setExportModal(false);
          },
          transponders: transponders, __self: this, __source: {fileName: _jsxFileName, lineNumber: 263}}
        )

        , React.createElement(GreisTransponderAdd, {
          open: addModal,
          close: () => {
            setAddModal(false);
          },
          addTransponders: addTransponders, __self: this, __source: {fileName: _jsxFileName, lineNumber: 271}}
        )

        , React.createElement(GreisTransponderTable, {
          ...{ transponders, updateTransponder, loading, admin }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}
        )
      )
    )
  );
}
